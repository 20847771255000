<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid}">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Cập nhật Đề xuất Xúc tiến đầu tư của Bộ, Ngành
          </CCardHeader>
          <CCardBody>
            <CForm>
              <div class="w-100">
                <div class="pdf-wrapper">
                  <div class="">
                    <div class="pdf-page">
                      <div class="fs-16">
                        <div id="pdf1" class="pdf-content">
                          <div class="text-center fs-16">
                            <strong class="bold">Mẫu C.II.1</strong><br>
                            <strong>Văn bản dự kiến chương trình xúc tiến đầu tư hàng năm</strong><br>
                            <p class="font-italic">(Áp dụng đối với chương trình xúc tiến đầu tư của Bộ/Ủy ban nhân dân
                              cấp
                              tỉnh theo quy định tại Điểm a, Khoản 2, Điều 93 Nghị định số 31/2021/NĐ-CP ngày
                              26/3/2021)</p>
                            <hr>
                          </div>
                          <div class="row fs-16">
                            <div class="col-md-6">
                              <ValidationProvider name="Tên Bộ/UBND Tỉnh" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                                    <input id="tenBo_UBNDTinh" type="text" class="form-control"
                                           placeholder="Tên Bộ/UBND Tỉnh"
                                           :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                           v-model="detailItem.tenBo_UBNDTinh"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CInput placeholder="Tên Bộ/UBND Tỉnh" horizontal :value.sync="detailItem.tenCoQuanChuTri"/>-->
                              <ValidationProvider name="Số văn bản" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                                    <input id="soVanBan" type="text" class="form-control"
                                           placeholder="Số:"
                                           :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                           v-model="detailItem.soVanBan"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CInput placeholder="Số:" horizontal :value.sync="detailItem.soVanBan"/>-->
                              <p class="font-weight">V/v dự kiến chương trình đầu tư năm
                                {{ detailItem.nam_ChuongTrinh }}</p>
                            </div>
                            <div class="col-md-6">
                              <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt
                                Nam</p>
                              <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                              <p class="font-weight font-italic" style="float: right; display: flex">
                                <ValidationProvider name="Địa điểm" rules="required"
                                                    v-slot="{ errors, valid , touched }">
                                  <div role="group" class="form-group form-row">
                                    <div class="col-sm-9">
                                      <input id="diaDiem" type="text" class="form-control"
                                         placeholder="Địa điểm"
                                         :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                         v-model="detailItem.diaDiem"/>
                                      <div class="invalid-feedback" v-if="!valid">
                                        <ul>
                                          <li v-for="e in errors" :key="e">{{ e }}</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </ValidationProvider>
                              <span class="mt-2">, ngày {{
                                  getDay(detailItem.ngayVanBan)
                                }} tháng {{
                                  getMonth(detailItem.ngayVanBan)
                                }} năm {{ getYear(detailItem.ngayVanBan) }}</span>
                              </p>
                            </div>
                          </div>
                          <div class="text-center">
                            <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
                          </div>
                          <div>
                            <b>I. Dự kiến chương trình xúc tiến đầu tư năm
                              {{ detailItem.nam_ChuongTrinh }}</b>
                            <div class="col-12">
                              <p>1. Đánh giá sơ bộ hoạt động xúc tiến đầu tư của năm trước:</p>
                              <ValidationProvider name="Đánh giá hoạt động năm trước" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea id="danhGiaHoatDongNamTruoc" type="text" class="form-control" :rows="1"
                                        placeholder="Nhập đánh giá"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="detailItem.danhGiaHoatDongNamTruoc"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CTextarea :rows="1" placeholder="Nhập đánh giá" horizontal :value.sync="item.danhGiaHoatDongNamTruoc"/>-->
                              <p>2. Quan điểm, định hướng, mục tiêu của chương trình xúc tiến đầu tư:</p>
                              <ValidationProvider name="Quan điểm, định hướng, mục tiêu" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea id="quanDiemDinhHuongMucTieu" type="text" class="form-control" :rows="1"
                                        placeholder="Nhập định hướng"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="detailItem.quanDiemDinhHuongMucTieu"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CTextarea :rows="1" placeholder="Nhập quan điểm, định hướng, mục tiêu" horizontal-->
                              <!--                                   :value.sync="detailItem.diaDiem"/>-->
                              <p>3. Dự kiến chương trình xúc tiến đầu tư (trong đó nêu rõ lý do, sự phù hợp...):</p>
                              <ValidationProvider name="Dự kiến CT XTĐT" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea id="duKienChuongTrinh" type="text" class="form-control" :rows="1"
                                        placeholder="Nhập dự kiến CT XTĐT"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="detailItem.duKienChuongTrinh"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CTextarea :rows="1" placeholder="Nhập chương trình dự kiến" horizontal-->
                              <!--                                   :value.sync="detailItem.diaDiem"/>-->
                            </div>
                          </div>
                          <div>
                            <b>II. Tổ chức thực hiện</b>
                            <div class="col-12">
                              <ValidationProvider name="Tổ chức thực hiện" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea id="toChucThucHien" type="text" class="form-control" :rows="1"
                                        placeholder="Nhập (các) tổ chức"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="detailItem.toChucThucHien"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div>
                            <b>III. Kiến nghị, đề xuất</b>
                            <div class="col-12 row">
                              <div class="col">
                                <p class="font-italic">Phụ lục gửi kèm</p>
                                <p>(i) Biểu tổng hợp dự kiến chương trình xúc tiến đầu tư năm
                                  {{ detailItem.nam_ChuongTrinh }} <span class="font-italic">(Mẫu C.II.2)</span>;</p>
                                <p>(ii) Danh mục dự án đầu tư đang có nhà đầu tư quan tâm <span class="font-italic">(Mẫu A.IV.4)</span>.
                                </p>
                              </div>
                              <div class="col mt-5">
                                <CButton size="sm" class="mt-3 ml-3" color="primary"
                                         @click="toggleCreateOrUpdatePhuLucModal({show:true})">
                                  <CIcon name="cil-pencil"/>
                                  Cập nhật phụ lục
                                </CButton>
                              </div>
                            </div>
                            <div class="col-12 row">
                              <div class="col">
                                <div class="float-left">
                                  <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>
                                  <span class="font-weight">- Như trên;</span> <br>
                                  <span class="font-weight">- Lưu: VT, ...</span> <br>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 row">
                              <div class="col"></div>
                              <div class="col text-center">
                                <span class="font-weight-bold text-uppercase">Thủ trưởng cơ quan</span> <br>
                                <span class="font-weight font-italic">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span>
                                <br>
                                <br>
                                <br>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row mb-0 mt-3">
                            <div class="col-12">
                              <b class="mt-5">Đính kèm file</b>
                              <FileUpload ref="upload" @onChange="changeFiles" :type="type" :attachments="Attachments"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CForm>
            <CElementCover v-if="isLoading"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="primary" class="mr-2" @click="handleSubmit(saveItem)" :disabled="invalid || isSaving">Lưu
              lại
            </CButton>
            <CButton color="danger" class="mr-2" @click="handleSubmit(saveItem2)" :disabled="invalid || isSaving">Nộp
              văn bản
            </CButton>
            <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
            <CButton color="secondary" class="mr-2" @click="back">Quay lại</CButton>
          </CCardFooter>
          <DeXuatCTXTDTPhuLucUpdateModal/>
        </ValidationObserver>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import {
  GET_DEXUATCTXTDT, TOGGLE_UPDATE_PHULUC_MODAL, UPDATE_DEXUATCTXTDT
} from '@/store/modules/DeXuatCTXTDT_BoNganh/actionTypes'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import moment from 'moment'
import DeXuatCTXTDTPhuLucUpdateModal from '@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTPhuLucUpdateModal'
import FileUpload from '@/components/file-upload/FileUpload'

export default {
  name: 'DeXuatCTXTDTUpdate',
  components: {
    DeXuatCTXTDTPhuLucUpdateModal,
    FileUpload
  },
  data () {
    return {
      invalid: true,
      Attachments: [],
      type: 'DeXuatCTXTDT_BoNganh',
      isLoading: false,
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      isDeleteConfirmationModalOpen: false,
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: [],
      item: this.detailItem
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDT', {
      detailItem: 'detailItem',
      isFetchingSingle: 'isFetchingSingle',
      isSaving: 'isSaving',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDT', {
      getItem: GET_DEXUATCTXTDT,
      updateItem: UPDATE_DEXUATCTXTDT,
      toggleCreateOrUpdatePhuLucModal: TOGGLE_UPDATE_PHULUC_MODAL
    }),
    print () {
      localStorage.setItem('de_xuat_ct_xtdt', JSON.stringify(this.detailItem))
      const routeData = this.$router.resolve({ path: '/de-xuat-ct-xtdt/bieu-in' })
      window.open(routeData.href, '_blank')
    },
    back () {
      this.$router.push({ path: '/de-xuat-ct-xtdt' })
    },
    async saveItem () {
      const validate = await this.$refs.form.validate()
      console.log(validate)
      if (validate) {
        this.detailItem.TrangThai = 1
        this.detailItem.dinhKem = JSON.stringify(this.Attachments)
        // if (this.item.id) {
        //   await this.updateItem(this.item)
        // } else {
        await this.updateItem(this.detailItem)
        // }
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          // this.toggleModal({ show: false })
          // this.$emit('refresh-list', true)
          await this.$router.push({ path: '/de-xuat-ct-xtdt' })
        }
      } else {
        this.$toast.error('Thông tin đang thiếu hoặc sai định dạng. Vui lòng kiểm tra lại')
      }
    },
    async saveItem2 () {
      const validate = await this.$refs.form.validate()
      if (validate) {
        this.detailItem.TrangThai = 2
        this.detailItem.dinhKem = JSON.stringify(this.Attachments)
        // if (this.item.id) {
        //   await this.updateItem(this.item)
        // } else {
        await this.updateItem(this.detailItem)
        // }
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          // this.toggleModal({ show: false })
          // this.$emit('refresh-list', true)
          await this.$router.push({ path: '/de-xuat-ct-xtdt' })
        }
      } else {
        this.$toast.error('Thông tin đang thiếu hoặc sai định dạng. Vui lòng kiểm tra lại')
      }
    },
    changeFiles (data) {
      this.Attachments = data.ListFileAttachAdd
    },
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    await this.getItem(this.$route.params.id)
    if (this.detailItem.dinhKem) this.Attachments = JSON.parse(this.detailItem.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
  }
}
</script>

<style scoped>

</style>
